<template>
    <div class="foot-wrap">
        <!-- pc布局【底部】 -->
        <b-container class="pc-footer">
            <b-row>
                <b-col>
                    <div class="pc-title">{{$t('message.linkus')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-foot-img">
                        <span class="pc-footicon pc-fz"><a href="mailto:luxi.37express@gmail.com"></a></span>
                        <span class="pc-footicon pc-fly"><a href="mailto:luxi.37express@gmail.com"></a></span>
                    </div>
                    <div class="pc-footcity" v-show="languageName=='cn'">
                        <span class="pc-footicon pc-china"></span>中国
                    </div>
                    <div class="pc-footcity" v-show="languageName=='en'">
                        <span class="pc-footicon pc-uk"></span>English
                    </div>
                    <div class="pc-footcity" v-show="languageName=='it'">
                        <span class="pc-footicon pc-italy"></span>Italia
                    </div>
                </b-col>
                <b-col>
                    <div class="pc-title">{{$t('message.shoptype')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-txtes" v-for="(item,index) in oneTypeArr" :key="index" @click="getShopList(index)">
                        {{languageName=='cn'?item.cn_title:''}}
                        {{languageName=='en'?item.en_title:''}}
                        {{languageName=='it'?item.it_title:''}}
                    </div>
                </b-col>
                <b-col>
                    <div class="pc-title">{{$t('message.aboutus')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:28}}">{{$t('message.usoutjoin')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:29}}">{{$t('message.uspromise')}}</router-link></div>
                </b-col>
                <b-col>
                    <div class="pc-title">{{$t('message.plan')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:17}}">{{$t('message.guardianplan')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:3}}">{{$t('message.illicittrade')}}</router-link></div>
                </b-col>
                <b-col>
                    <div class="pc-title">{{$t('message.servecenter')}}</div>
                    <div class="pc-bor"></div>
                    <!-- <div class="pc-txtes"><router-link to="/cooperation">{{$t('message.cooperation')}}</router-link></div> -->
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:24}}">{{$t('message.warrantybill')}}</router-link></div>
                    <div class="pc-txtes"><router-link to="/problem">{{$t('message.seeproblem')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:23}}">{{$t('message.zzauto')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:31}}">{{$t('message.linkus')}}</router-link></div>
                    <div class="pc-txtes"><router-link to="/map">{{$t('message.shopfixed')}}</router-link></div>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="pc-footer">
            <div class="pc-txtes">{{$t('message.dischild')}}</div>
            <div class="pc-txtes">{{$t('message.discenten')}}</div>
            <div class="pc-txtes">{{$t('message.disfoot')}}</div>
            <div class="pc-txtes pc-mtp"><span @click="getLink(26)">{{$t('message.footnum')}}</span> | <span @click="getLink(22)">{{$t('message.footext')}}</span></div>
            <div class="pc-txtes">&copy;2022 37 Vape S.R.L.</div>
        </b-container>
        <!-- wap布局【底部】 -->
        <b-container class="wap-footer">
            <el-row class="wap-list">
                <el-col :span="12">
                    <div class="pc-title">{{$t('message.shoptype')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-txtes" v-for="(item,index) in oneTypeArr" :key="index" @click="getShopList(index)">
                        {{languageName=='cn'?item.cn_title:''}}
                        {{languageName=='en'?item.en_title:''}}
                        {{languageName=='it'?item.it_title:''}}
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="pc-title">{{$t('message.aboutus')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:28}}">{{$t('message.usoutjoin')}}</router-link></div>
                    <div class="pc-txtes"><router-link  :to="{name:'article',query:{id:29}}">{{$t('message.uspromise')}}</router-link></div>
                </el-col>
                <el-col :span="12">
                    <div class="pc-title">{{$t('message.plan')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:17}}">{{$t('message.guardianplan')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:3}}">{{$t('message.illicittrade')}}</router-link></div>
                </el-col>
                <el-col :span="12">
                    <div class="pc-title">{{$t('message.servecenter')}}</div>
                    <div class="pc-bor"></div>
                    <div class="pc-txtes"><router-link to="/cooperation">{{$t('message.cooperation')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:24}}">{{$t('message.warrantybill')}}</router-link></div>
                    <div class="pc-txtes"><router-link to="/problem">{{$t('message.seeproblem')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:23}}">{{$t('message.zzauto')}}</router-link></div>
                    <div class="pc-txtes"><router-link :to="{name:'article',query:{id:31}}">{{$t('message.linkus')}}</router-link></div>
                    <div class="pc-txtes"><router-link to="/map">{{$t('message.shopfixed')}}</router-link></div>
                </el-col>
            </el-row>
            <b-row class="txtrow">
                <div class="wap-txtes">{{$t('message.dischild')}}</div>
                <div class="wap-txtes">{{$t('message.discenten')}}</div>
                <div class="wap-txtes">{{$t('message.disfoot')}}</div>
            </b-row>
            <b-row class="boxrow">
                <b-col>
                    <div class="wap-title">{{$t('message.linkus')}}</div>
                    <div class="wap-foot-img">
                        <span class="wap-footicon wap-fz"><a href="mailto:luxi.37express@gmail.com"></a></span>
                        <span class="wap-footicon wap-fly"><a href="mailto:luxi.37express@gmail.com"></a></span>
                    </div>
                    <div class="wap-footcity" v-show="languageName=='cn'">
                        <span class="wap-footicon wap-china"></span>中国
                    </div>
                    <div class="wap-footcity" v-show="languageName=='en'">
                        <span class="wap-footicon wap-uk"></span>English
                    </div>
                    <div class="wap-footcity" v-show="languageName=='it'">
                        <span class="wap-footicon wap-italy"></span>Italia
                    </div>
                </b-col>
            </b-row>
            <b-row class="footrow">
                <div class="wap-txtes">&copy;2022 37 Vape S.R.L.</div>
                <div class="wap-txtes wap-mtp"><span @click="getLink(26)">{{$t('message.footnum')}}</span> | <span @click="getLink(22)">{{$t('message.footext')}}</span></div>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import {yooz_lang} from '@/untils/common';
export default {
    name:'',
    inject:['reload'],
    data() {
        return {
            languageName:'',
            oneTypeArr:[]
        }
    },
    methods: {
        //商品导航数据【顶级分类和首页推荐商品数据】
        getNavShopList(){
            this.$http.api_goods_levelone().then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.oneTypeArr = res.data;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 去查看文章详情
        getLink(id){
            this.$router.push({path:'/article',query:{id:id}});
        },
        // 去查看商品列表
        getShopList(index){
            this.reload();
            this.$router.push({path:'/list',query:{type:index}});
        },
    },
    created(){
        let that = this;
        that.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        that.$root.Bus.$on('languageClick', function (msg) {
            that.languageName = msg.key;
            that.reload();
        })
        that.getNavShopList();//商品导航数据
    }
}
</script>
<style lang="scss" scoped>
.foot-wrap{
    width: 100%;
    background:#262626;
    color: #fff;
    padding: 30px 0;
}
.pc-title{
    cursor: pointer;
    margin: 10px 0;
    color: #fff;
}
.pc-foot-img{
    padding:10px 0;
    .pc-footicon{
        display: inline-block;
        width:25px;
        height:25px;
        margin-right: 20px;
        a{
            display: inline-block;
            width:25px;
            height:25px;
        }
    }
    .pc-fz{
        background:url(~@/assets/images/icon_facebook.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .pc-fly{
        background:url(~@/assets/images/icon_tuitetwitte.png) no-repeat left top;
        background-size: 100% 100%;
    }
}
.pc-footcity{
    padding:10px 0;
    color: #fff;
    .pc-footicon{
        display: inline-block;
        width:30px;
        height:20px;
        margin-right: 5px;
        vertical-align: middle;
        a{
            display: inline-block;
            width:30px;
            height:20px;
        }
    }
    .pc-china{
        background:url(~@/assets/images/icon_china.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .pc-uk{
        background:url(~@/assets/images/icon_uk.png) no-repeat left top;
        background-size: 100% 100%;
    }
    .pc-italy{
        background:url(~@/assets/images/icon_italy.png) no-repeat left top;
        background-size: 100% 100%;
    }
}
.pc-bor{
    width:35px;
    height:1px;
    background: #fff;
    margin: 15px 0;
}
.pc-txtes{
    cursor: pointer;
    margin: 10px 0;
    color: #fff;
    span{
        color: #fff;
    }
    a{
        color: #fff;
        text-decoration:none;
    }
}
.pc-mtp{
    margin-top: 30px;
}
@media screen and (min-width: 1200px) {
    .wap-footer{display: none;}
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wap-footer{display: none;}
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wap-footer{display: none;}
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .pc-footer{display: none;}
    .wap-footer{
        padding: 0 0;
        .wap-list{
            padding: 0 10px;
            margin: 0;
        }
        .txtrow{
            padding:  10px;
            margin: 0;
            .wap-txtes{
                width: 100%;
                color: #fff;
                
                padding:5px 0;
                span{
                    color: #fff;
                    
                }
            }
        }
        .boxrow{
            padding: 10px 10px 0;
            margin: 0;
            justify-content: center;
            .col{
                text-align: center;
                .wap-title{
                    display: flex;
                    cursor: pointer;
                    justify-content: center;
                    margin: 10px 0;
                    color: #fff;
                }
                                
                
                .wap-foot-img{
                    padding:10px 0;
                    .wap-footicon{
                        display: inline-block;
                        width:25px;
                        height:25px;
                        margin:0 10px;
                        a{
                            display: inline-block;
                            width:25px;
                            height:25px;
                        }
                    }
                    .wap-fz{
                        background:url(~@/assets/images/icon_facebook.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-fly{
                        background:url(~@/assets/images/icon_tuitetwitte.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
                .wap-footcity{
                    padding:10px 0;
                    color: #fff;
                    text-align: center;
                    .wap-footicon{
                        display: inline-block;
                        width:30px;
                        height:20px;
                        margin-right: 5px;
                        vertical-align: middle;
                        a{
                            display: inline-block;
                            width:30px;
                            height:20px;
                        }
                    }
                    .wap-china{
                        background:url(~@/assets/images/icon_china.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-uk{
                        background:url(~@/assets/images/icon_uk.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-italy{
                        background:url(~@/assets/images/icon_italy.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .footrow{
            width: 100%;
            padding: 20px 10px 0;
            margin: 0;
            justify-content: center;
            
            .wap-txtes{
                cursor: pointer;
                margin: 0px 0;
                text-align: center;
                color: #fff;
                
                a{
                    color: #fff;
                    text-decoration:none;
                    
                }
            }
            .wap-mtp{
                margin-top: 10px;
            }   
        }
    }
}
@media screen and (max-width: 479px) {
    .pc-footer{display: none;}
    .wap-footer{
        padding: 0 0;
        .wap-list{
            padding: 0 10px;
            margin: 0;
        }
        .txtrow{
            padding:  10px;
            margin: 0;
            .wap-txtes{
                width: 100%;
                color: #fff;
                
                padding:5px 0;
                
            }
        }
        .boxrow{
            padding: 10px 10px 0;
            margin: 0;
            justify-content: center;
            .col{
                text-align: center;
                .wap-title{
                    display: flex;
                    cursor: pointer;
                    justify-content: center;
                    margin: 10px 0;
                    color: #fff;
                }
                                
                
                .wap-foot-img{
                    padding:10px 0;
                    .wap-footicon{
                        display: inline-block;
                        width:25px;
                        height:25px;
                        margin:0 10px;
                        a{
                            display: inline-block;
                            width:25px;
                            height:25px;
                        }
                    }
                    .wap-fz{
                        background:url(~@/assets/images/icon_facebook.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap-fly{
                        background:url(~@/assets/images/icon_tuitetwitte.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
                .wap-footcity{
                    padding:10px 0;
                    color: #fff;
                    text-align: center;
                    .wap-footicon{
                        display: inline-block;
                        width:30px;
                        height:20px;
                        margin-right: 5px;
                        vertical-align: middle;
                        a{
                            display: inline-block;
                            width:30px;
                            height:20px;
                        }
                    }
                    .wap-china{
                        background:url(~@/assets/images/icon_china.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap_uk{
                        background:url(~@/assets/images/icon_uk.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .wap_italy{
                        background:url(~@/assets/images/icon_italy.png) no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .footrow{
            padding: 20px 10px 0;
            margin: 0;
            justify-content: center;
            width: 100%;
            .wap-txtes{
                width: 100%;
                cursor: pointer;
                margin: 0px 0;
                text-align: center;
                color: #fff;
                
                
                a{
                    color: #fff;
                    text-decoration:none;
                    
                }
            }
            .wap-mtp{
                margin-top: 10px;
            }   
        }
    }
}
</style>